<template>
  <div
    class="about"
    :style="`padding-top: ${(isApp ? 224 : 192) + systemBarHeight}px`"
  >
    <h1>This is an about page</h1>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "about",
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
    }),
  },
  created() {},
};
</script>

<style>
.about {
  height: 1300px;
}
</style>
